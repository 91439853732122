<template>
  <div>
    <v-row>
      <v-col cols="12"
        ><v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="text-h5 d-flex justify-center">
                Ваш баланс {{ formatPrice(userBalance) }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="item in balanceRecords"
                :key="`balance-record-${item._id}`"
                class="d-flex"
              >
                {{ item.description }}<v-spacer></v-spacer>
                <div class="text-h5 green--text">
                  {{ formatPrice(item.amount) }}
                </div>
              </div>
              <div v-if="spent > 0" class="d-flex">
                На товары потрачено<v-spacer></v-spacer>
                <div class="text-h5 red--text">{{ formatPrice(spent) }}</div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="text-h5 d-flex justify-center">Правила</div>
              <template v-slot:actions>
                <v-icon color="primary"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <rules />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div class="d-flex flex-column">
      <v-sheet
        v-for="product in displayProducts"
        :key="`product-card-${product._id}`"
        class="ma-4"
      >
        <div class="d-flex">
          <v-img
            v-if="product.image"
            :src="product.image && product.image.replace(/\s/gi, '')"
            style="
              max-width: 300px;
              display: flex;
              text-align: center;
              cursor: pointer;
              height: 100%;
            "
            class="align-self-stretch justify-self-stretch d-none d-md-block"
            cover
            @click="
              selectedZoomImageIndex = 0;
              zoomImages = product.params.images;
              isImagesModalOpen = true;
            "
            ><div
              v-if="
                product.params &&
                product.params.images &&
                product.params.images.length
              "
              class="pa-4"
              style="color: white; font-size: 48px"
            >
              <span
                v-for="index in product.params.images.length"
                :key="`img-dot-dot-${index}`"
                class="mx-2"
                >.</span
              >
            </div></v-img
          >
          <v-card
            :style="{
              ...(productPurchasesByProductIDs[product._id]
                ? {
                    backgroundColor: '#FAD099',
                  }
                : {}),
            }"
            class="align-self-stretch justify-self-stretch"
          >
            <v-img
              height="400"
              :src="product.image && product.image.replace(/\s/gi, '')"
              class="align-self-stretch justify-self-stretch d-md-none"
              cover
            ></v-img>
            <v-card-title class="align-start" style="word-break: break-word">{{
              product.title
            }}</v-card-title>
            <v-card-text>
              <div class="mb-2">
                {{ product.description }}
              </div>
              <div class="mb-2" v-if="product.params.specs">
                <em>{{ product.params.specs }}</em>
              </div>
              <v-divider></v-divider>
              <div class="d-flex mt-2">
                <strong>Цена</strong>
                <v-spacer></v-spacer>
                <div class="text-h5">{{ formatPrice(product.price) }}</div>
              </div>
              <div class="d-flex mt-2" v-if="product.remaining > 0">
                <strong>Осталось</strong>
                <v-spacer></v-spacer>
                {{ product.remaining }} шт.
              </div>
              <div class="d-flex mt-2" v-else>
                <strong>Этот подарок уже разобрали</strong>
              </div>
              <div
                class="d-flex mt-2"
                v-if="
                  productPurchasesByProductIDs[product._id] &&
                  productPurchasesByProductIDs[product._id].quantity
                "
              >
                <strong>Вы приобрели</strong>
                <v-spacer></v-spacer>
                <strong
                  >{{
                    productPurchasesByProductIDs[product._id].quantity
                  }}
                  шт.</strong
                >
              </div>
            </v-card-text>
            <v-card-actions>
              <template
                v-if="product.price <= userBalance && product.remaining > 0"
              >
                <v-btn
                  v-if="
                    !productPurchasesByProductIDs[product._id] ||
                    productPurchasesByProductIDs[product._id].quantity === 0
                  "
                  text
                  color="red"
                  :loading="loading"
                  @click="onProductQuantityUpdate(product, 1)"
                  >Приобрести</v-btn
                >
                <v-btn
                  v-if="
                    productPurchasesByProductIDs[product._id] &&
                    productPurchasesByProductIDs[product._id].quantity < 3
                  "
                  text
                  color="red"
                  :loading="loading"
                  @click="
                    onProductQuantityUpdate(
                      product,
                      productPurchasesByProductIDs[product._id].quantity + 1
                    )
                  "
                  >Приобрести еще 1 шт.</v-btn
                >
              </template>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="secondary"
                @click="onProductQuantityUpdate(product, 0)"
                :loading="loading"
                v-if="
                  productPurchasesByProductIDs[product._id] &&
                  productPurchasesByProductIDs[product._id].quantity
                "
                >Отменить</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </div>
    <v-dialog fullscreen :value="isImagesModalOpen">
      <div style="width: 100%; height: 100vh; position: relative">
        <div
          style="
            top: 10px;
            right: 10px;
            position: absolute;
            color: white;
            z-index: 100;
          "
        >
          <v-btn icon color="white">
            <v-icon @click="isImagesModalOpen = false">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-carousel
          :cycle="false"
          style="width: 100%; background-color: #000d"
          height="100vh"
          :show-arrows="true"
          :continuous="true"
          v-model="selectedZoomImageIndex"
        >
          <v-carousel-item
            v-for="(item, index) of zoomImages"
            style="height: 100%; width: 100%"
            :key="index"
          >
            <v-img
              :src="item && item.replace(/\s/gi, '')"
              style="width: 100%; height: 100%"
              :contain="true"
            />
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import Rules from "./Rules.vue";
import Vue from "vue";

export default {
  name: "OTR2023Shop",
  components: {
    Rules,
  },
  data() {
    return {
      loading: true,
      products: [],
      balanceRecords: [],
      productPurchases: [],
      roubleNumberFormat: Intl.NumberFormat("ru-RU"),
      apiMessage: null,
      isImagesModalOpen: false,
      zoomImages: null,
      selectedZoomImageIndex: 0,
    };
  },
  created() {
    this.fetchProducts();
    this.fetchBalanceRecords();
    this.fetchProductPurchases();
  },
  computed: {
    productPurchasesByProductIDs() {
      return _.keyBy(this.productPurchases, "product");
    },
    income() {
      return _.reduce(
        this.balanceRecords,
        (total, { amount }) => {
          return total + amount;
        },
        0
      );
    },
    spent() {
      return _.reduce(
        this.productPurchases,
        (total, { amount }) => {
          return total + amount;
        },
        0
      );
    },
    userBalance() {
      return this.income - this.spent;
    },
    userBalanceStr() {
      return this.roubleNumberFormat.format(this.userBalance);
    },
    displayProducts() {
      return _.map(this.products, (item) => {
        return {
          ...item,
          remaining: Math.max(0, item.quantity - item.purchased),
        };
      });
    },
  },
  methods: {
    ...mapActions({
      getProducts: "event/getShopProducts",
      getBalanceRecords: "event/getShopBalanceRecords",
      getProductPurchases: "event/getShopProductPurchases",
      postProductPurchase: "event/postShopProductPurchase",
    }),
    formatPrice(num) {
      return `${this.roubleNumberFormat.format(num).replace(",", ".")}`;
    },
    async fetchProducts() {
      try {
        this.loading = true;
        const { data } = await this.getProducts();
        this.products = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchBalanceRecords() {
      try {
        const { data } = await this.getBalanceRecords();
        this.balanceRecords = data;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchProductPurchases() {
      try {
        const { data } = await this.getProductPurchases();
        this.productPurchases = data;
        console.log(data);
      } catch (err) {
        console.error(err);
      }
    },
    async onProductQuantityUpdate(product, newAmount) {
      try {
        this.loading = true;
        const numNewAmount = Number.parseInt(newAmount);
        if (Number.isNaN(numNewAmount)) return;

        const payload = {
          product: product._id,
          quantity: numNewAmount,
        };
        const { data } = await this.postProductPurchase(payload);
        this.apiMessage = data && data.message;
        if (this.apiMessage) {
          Vue.$toast.open({
            type: "info",
            message: this.apiMessage,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }

        await this.fetchProducts();
        await this.fetchBalanceRecords();
        await this.fetchProductPurchases();
      } catch (err) {
        this.apiMessage = err && err.message;
        if (this.apiMessage) {
          Vue.$toast.open({
            type: "error",
            message: this.apiMessage,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
