var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('div',{staticClass:"text-h5 d-flex justify-center"},[_vm._v(" Ваш баланс "+_vm._s(_vm.formatPrice(_vm.userBalance))+" ")])]),_c('v-expansion-panel-content',[_vm._l((_vm.balanceRecords),function(item){return _c('div',{key:("balance-record-" + (item._id)),staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.description)),_c('v-spacer'),_c('div',{staticClass:"text-h5 green--text"},[_vm._v(" "+_vm._s(_vm.formatPrice(item.amount))+" ")])],1)}),(_vm.spent > 0)?_c('div',{staticClass:"d-flex"},[_vm._v(" На товары потрачено"),_c('v-spacer'),_c('div',{staticClass:"text-h5 red--text"},[_vm._v(_vm._s(_vm.formatPrice(_vm.spent)))])],1):_vm._e()],2)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" $expand ")])]},proxy:true}])},[_c('div',{staticClass:"text-h5 d-flex justify-center"},[_vm._v("Правила")])]),_c('v-expansion-panel-content',[_c('rules')],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.displayProducts),function(product){return _c('v-sheet',{key:("product-card-" + (product._id)),staticClass:"ma-4"},[_c('div',{staticClass:"d-flex"},[(product.image)?_c('v-img',{staticClass:"align-self-stretch justify-self-stretch d-none d-md-block",staticStyle:{"max-width":"300px","display":"flex","text-align":"center","cursor":"pointer","height":"100%"},attrs:{"src":product.image && product.image.replace(/\s/gi, ''),"cover":""},on:{"click":function($event){_vm.selectedZoomImageIndex = 0;
            _vm.zoomImages = product.params.images;
            _vm.isImagesModalOpen = true;}}},[(
              product.params &&
              product.params.images &&
              product.params.images.length
            )?_c('div',{staticClass:"pa-4",staticStyle:{"color":"white","font-size":"48px"}},_vm._l((product.params.images.length),function(index){return _c('span',{key:("img-dot-dot-" + index),staticClass:"mx-2"},[_vm._v(".")])}),0):_vm._e()]):_vm._e(),_c('v-card',{staticClass:"align-self-stretch justify-self-stretch",style:(Object.assign({}, (_vm.productPurchasesByProductIDs[product._id]
              ? {
                  backgroundColor: '#FAD099',
                }
              : {})))},[_c('v-img',{staticClass:"align-self-stretch justify-self-stretch d-md-none",attrs:{"height":"400","src":product.image && product.image.replace(/\s/gi, ''),"cover":""}}),_c('v-card-title',{staticClass:"align-start",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(product.title))]),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(product.description)+" ")]),(product.params.specs)?_c('div',{staticClass:"mb-2"},[_c('em',[_vm._v(_vm._s(product.params.specs))])]):_vm._e(),_c('v-divider'),_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Цена")]),_c('v-spacer'),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formatPrice(product.price)))])],1),(product.remaining > 0)?_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Осталось")]),_c('v-spacer'),_vm._v(" "+_vm._s(product.remaining)+" шт. ")],1):_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Этот подарок уже разобрали")])]),(
                _vm.productPurchasesByProductIDs[product._id] &&
                _vm.productPurchasesByProductIDs[product._id].quantity
              )?_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Вы приобрели")]),_c('v-spacer'),_c('strong',[_vm._v(_vm._s(_vm.productPurchasesByProductIDs[product._id].quantity)+" шт.")])],1):_vm._e()],1),_c('v-card-actions',[(product.price <= _vm.userBalance && product.remaining > 0)?[(
                  !_vm.productPurchasesByProductIDs[product._id] ||
                  _vm.productPurchasesByProductIDs[product._id].quantity === 0
                )?_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(product, 1)}}},[_vm._v("Приобрести")]):_vm._e(),(
                  _vm.productPurchasesByProductIDs[product._id] &&
                  _vm.productPurchasesByProductIDs[product._id].quantity < 3
                )?_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(
                    product,
                    _vm.productPurchasesByProductIDs[product._id].quantity + 1
                  )}}},[_vm._v("Приобрести еще 1 шт.")]):_vm._e()]:_vm._e(),_c('v-spacer'),(
                _vm.productPurchasesByProductIDs[product._id] &&
                _vm.productPurchasesByProductIDs[product._id].quantity
              )?_c('v-btn',{attrs:{"text":"","color":"secondary","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(product, 0)}}},[_vm._v("Отменить")]):_vm._e()],2)],1)],1)])}),1),_c('v-dialog',{attrs:{"fullscreen":"","value":_vm.isImagesModalOpen}},[_c('div',{staticStyle:{"width":"100%","height":"100vh","position":"relative"}},[_c('div',{staticStyle:{"top":"10px","right":"10px","position":"absolute","color":"white","z-index":"100"}},[_c('v-btn',{attrs:{"icon":"","color":"white"}},[_c('v-icon',{on:{"click":function($event){_vm.isImagesModalOpen = false}}},[_vm._v("mdi-close")])],1)],1),_c('v-carousel',{staticStyle:{"width":"100%","background-color":"#000d"},attrs:{"cycle":false,"height":"100vh","show-arrows":true,"continuous":true},model:{value:(_vm.selectedZoomImageIndex),callback:function ($$v) {_vm.selectedZoomImageIndex=$$v},expression:"selectedZoomImageIndex"}},_vm._l((_vm.zoomImages),function(item,index){return _c('v-carousel-item',{key:index,staticStyle:{"height":"100%","width":"100%"}},[_c('v-img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item && item.replace(/\s/gi, ''),"contain":true}})],1)}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }