<template>
  <div>
    <p>Друзья!</p>

    <p>Магазин подарков работает <strong>с 22 по 27 декабря.</strong></p>
    <p>
      У каждого предмета в магазине есть своя стоимость в баллах. Для
      приобретения сувениров вам необходимо набрать нужное количество баллов,
      выполняя задания адвента.
    </p>

    <p>
      Вы можете изменять содержимое своей виртуальной корзины до
      <strong>23:59 27 декабря</strong>. После этого времени мы приостановим
      работу магазина и зафиксируем сделанный вами выбор.
    </p>

    <p>Подарки можно будет получить одним из двух способов:</p>

    <p>
      Забрать самостоятельно или через коллегу в офисе ОТР (Москва и филиалы).
    </p>
    <p>
      Запросить доставку по домашнему адресу в случае проживания в городе, где
      нет нашего офиса.
    </p>
    <p>
      Заполните форму по этой ссылке (<a
        href="https://hr.otr.ru/giftform"
        target="_blank"
        >https://hr.otr.ru/giftform</a
      >), чтобы сообщить нам о способе получения подарка.
    </p>

    <p>
      ВАЖНО!!! Покупая подарки в магазине вы не изменяете свои показатели по
      баллам в турнирной таблице. Это лишь сумма вашей виртуальной валюты. Если
      у вас 100 баллов, а вы купили товаров на 40, то:
    </p>

    <ul>
      <li>в турнирной таблице у вас остались ваши 100 баллов</li>
      <li>на покупки осталось лишь 60 баллов.</li>
    </ul>

    <p class="mt-4"><strong>⚠️ Ограничения: ⚠️</strong></p>

    <ol>
      <li>
        За обычную валюту подарки приобрести нельзя, только за игровые баллы.
      </li>
      <li>
        Передавать баллы между пользователями нельзя — «скинуться» для получения
        более ценного подарка не получится.
      </li>
      <li>Вы можете приобрести не более трёх одинаковых предметов.</li>
      <li>
        Если вы выбрали получение подарков в главном офисе в Москве, получить их
        можно будет до Нового года. Если вы планируете забрать подарки в филиале
        или заказали доставку на домашний адрес, подарки придут вам уже после
        праздников. Мы приложим все усилия, чтобы отправить подарки до Нового
        года, но, к сожалению, курьерские службы в основном переносят доставки с
        конца года на начало следующего.
      </li>
    </ol>

    <p class="mt-4"><strong>📦 Доставка подарков: 📦</strong></p>
    <p>
      Сотрудники из Москвы смогут получить свои подарки в офисах на Дмитровском
      шоссе (3-й этаж, 323 кабинет, Наталия Быстрицкая).
    </p>

    <p>
      Для сотрудников, проживающих в городе, где имеется филиал ОТР, будет
      организована доставка на адрес офиса.
    </p>
    <p>
      Для сотрудников, работающих удаленно из других городов, будет организована
      почтовая доставка на домашний адрес.
    </p>
  </div>
</template>

<script>
export default {
  name: "OTRShopRules",
};
</script>
